:root {
  --primary900: #311B32;
  --primary700: #552F57;
  --primary500: #874A89;
  --primary300:  #AC69AE;
  --primary100: #D5B4D6;
  --primary50: #E7DBE8;

  --secondary50: #ECEAC6;
  --secondary100: #EAE590;
  --secondary300: #DED648;
  --secondary500: #CCC31F;
  --secondary700: #999217;
  --secondary900: #59550D;

  --alert50: #F0B29F;
  --alert100: #EC957B;
  --alert300: #DD6E4C;
  --alert500: #CC4014;
  --alert700: #992F0F;
  --alert900: #5D1D09;

  --light50: #FFFFFF;
  --light100: #F6F6F6;
  --light300: #EDEDED;
  --light500: #E4E4E4;
  --light700: #D0D0D0;
  --light900: #B3B3B3;

  --shadow50: #1B1B1B;
  --shadow100: #2B2B2B;
  --shadow300: #404040;
  --shadow500: #646464;
  --shadow700: #888888;
  --shadow900: #999999;

  --light-bg50: #FFFFFF;
  --light-bg100: #FBFBFB;
  --light-bg300: #F8F8F8;
  --light-bg500: #F5F3F5;
  --light-bg700: #ECE9EC;
  --light-bg900: #E2E0E2;

  --dark-bg50: #7C6A7C;
  --dark-bg100: #705C70;
  --dark-bg300: #4C3E4C;
  --dark-bg500: #413541;
  --dark-bg700: #3A313A;
  --dark-bg900: #292329;  
}

#root{
  padding-top: env(safe-area-inset-top);
  width: 100vw;
  height: calc(100vh - env(safe-area-inset-top));
  scroll-behavior: smooth;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}


* {
  caret-color: transparent;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: var(--light-bg500);
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

textarea:focus, input:focus, select:focus, button:focus{
  outline: none;
}

textarea {
  font-family: 'Open Sans', sans-serif;
}

input, textarea {
  caret-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.disabled {
  background-color: var(--light700);
  color: var(--light900);
  user-select: none;
}

.map-marker-label {
  transform: translateY(-20%);
}

/* make spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

/* make spinning class */
.spin {
  animation: spin 1s infinite linear;
}


@media only screen and (max-width: 800px) {
}